import { defineStore } from "pinia";

//import { getDailyListAPI } from './api/login';
import {
  getBannerListAPI,
  getColumnListAPI,
  getMomentListAPI,
  getColumnRcmdListAPI,
  getRcmdListAPI,
  getColumnDetAPI,
  getDiaryDetAPI,
  getCommentListAPI,
  getShareRoomCmtListAPI,
  getTodayCateListAPI,
  saveTodayCateListAPI,
  getRoomListAPI,
  getMyRoomListAPI,
  getMyRoomDiaryListAPI,
  setDisclosureStatusAPI,
  insertMomentProcAPI,
  insertCmtAPI,
  updateCmtAPI,
  getJWJTrainingListAPI,
  tmpSaveMemoAPI,
  getTmpMemoAPI,
  getTodayAlarmAPI,
  getTodayListAPI,
  getPostingListAPI,
  getColumnAuthInfoAPI,
  getTodayDataPagingAPI,
} from "~/store/api/today";
import { getMyDailyChkListAPI } from "~/store/api/write";
import { useAuthStore } from "~/store/auth";
import dayjs from "dayjs";
import axios from "axios";

import { isEmpty, decodeChar } from "~/utils/utils";

export const useTodayStore = defineStore("today", () => {
  const authStore = useAuthStore();
  const { $origin } = useNuxtApp();
  const state = reactive({
    orderModalOpen: false,
    drag: false,
    bottomCmtModalOpen: false,
    /* contentsList: [
      // 투데이 화면에 표시될 컨텐츠 순서
      { name: 'column', component: () => import('@/components/today/Column.vue') },
      { name: 'daily', component: () => import('@/components/today/Daily.vue') },
      { name: 'diaryRecom', component: () => import('@/components/today/DiaryRecom.vue') },
      { name: 'family', component: () => import('@/components/today/Family.vue') },
      { name: 'sharingRoom', component: () => import('@/components/today/SharingRoom.vue') },
      { name: 'withJTraining', component: () => import('@/components/today/WithJTraining.vue') }
    ] */
    contentsList: [
      // 투데이 화면에 표시될 컨텐츠 순서
      /* {
        name: "유기성목사 칼럼",
        roomCd: "Column",
        componentName: "Column",
        category: "Column",
        visible: "Y",
      },
      {
        name: "일상동행",
        roomCd: "Moment",
        componentName: "Daily",
        category: "Moment",
        visible: "Y",
      },
      {
        name: "추천일기",
        roomCd: "Rcmd",
        componentName: "DiaryRecom",
        category: "Rcmd",
        visible: "Y",
      }, */
      /* {
        name: "예수동행훈련",
        roomCd: "365",
        componentName: "WithJTraining",
        category: "365",
        visible: "Y",
      }, */
      /* {
        name: '의민이네 가족',
        componentName: 'Sharing',
        category: '나눔방',
        visible: true
      } */
    ],
    //myRoomDiaryList: [],
    myRoomDiaryList: {},
    openRcmdList: [],
    columnList: [],
    groupPostingList: [],
    groupPostingPaging: {
      lastIdx: 0,
      isEnd: false,
    },
    withTodayStoryList: [],
    withTodayStoryPaging: {
      lastIdx: 0,
      isEnd: false,
    },
    columnPaging: {
      lastIdx: 0,
      isEnd: false,
    },
    grpPayload: {
      startNum: 0,
    },
    columnPayload: {
      memCd: "100",
      siteLangCd: "COMMONCD001000000001",
      boardCd: "Board000000000000005",
      diaryTypeCd: "COMMONCD007000000002",
      /* yyyy: dayjs().format('YYYY'),
      mm: dayjs().format('MM'), */
      startNum: 0,
    },
    bannerList: [],
    memoStartNum: 0,
    momentList: [],
    rcmdList: [],
    columnDet: {},
    diaryDet: {},
    cmtList: [],
    groupedCmt: [],
    trainingList: [],
    myAlarm: null,
    newList: [],
    columnAuth: {
      essayAuthCd: "",
      essayAuthName: "",
      fullOpenYN: "",
      grpCd: "",
      grpName: "",
    },
    roomPaging: {
      startNum: 0,
    },
    rcmdPaging: {
      startNum: 0,
    },
  });

  async function setOrderModal(boolean) {
    state.orderModalOpen = boolean;
    return boolean;
  }
  async function setDrag(boolean) {
    state.drag = boolean;

    if (state.drag === false) {
      // 순서 저장 호출
      //this.saveTodayCateListAction()

      saveTodayCateListAction(
        JSON.stringify(
          state.contentsList.map((itm) => ({
            visible: "Y",
            category: itm.category,
            ...(itm.category === "Sharing" && { code: itm.roomCd }),
          }))
        )
      );
    }

    return boolean;
  }
  async function getBannerList() {
    try {
      const hrefPattern = /<a[^>]*href=['"]([^'"]*)['"][^>]*>/;
      const srcPattern = /<img[^>]*src=['"](\/\/[^'"]+)['"][^>]*>/;
      const { statusText, data: res = {} } = await getBannerListAPI();
      if (statusText !== "OK" || res?.bannerList === undefined) throw Error;

      state.bannerList = [
        ...res.bannerList.map((itm) => {
          const hrefMatch = itm.postCnts.match(hrefPattern);
          const srcMatch = itm.postCnts.match(srcPattern);
          return {
            ...itm,
            //link: hrefMatch && hrefMatch[1] ? hrefMatch[1] : 'https://jwj.kr',
            //image: srcMatch && srcMatch[1] ? 'https:' + srcMatch[1] : ''
            link: hrefMatch?.[1] ? hrefMatch[1] : "https://jwj.kr",
            image: srcMatch?.[1] ? "https:" + srcMatch[1] : "",
          };
        }),
      ];

      return state.bannerList;
    } catch (err) {
      console.error(`getBannerList err : `, err);
    }
  }

  async function getWithTodayStoryList(payload) {
    if (isEmpty(state.withTodayStoryList) || state.withTodayStoryList.length === 0) {
      state.withTodayStoryPaging.lastIdx = 0;
      state.withTodayStoryPaging.isEnd = false;
    }

    if (state.withTodayStoryPaging.isEnd === false) {
      if (!isEmpty(state.withTodayStoryList) && state.withTodayStoryList.length > 0) {
        state.columnPayload.startNum = parseInt(state.columnPayload.startNum) + 10;
      }
      const { data: res = {} } = await getColumnListAPI({ ...state.columnPayload, memCd: 4 });
      if (res?.columnList === undefined) throw Error;

      if (res.columnList.length < 10) state.withTodayStoryPaging.isEnd = true;

      state.withTodayStoryList = [
        ...state.withTodayStoryList,
        ...res.columnList.map((itm) => ({
          ...itm,
          date: dayjs(itm.diarySortDate).format("YYYY-MM-DD"),
          thumbUrl: !isEmpty(itm.uploadBannerFileCD) ? `https://jwj.kr/upload/${itm.uploadBannerFileSavePath}/${itm.uploadBannerFileCD}_thumb` : "",
          imgSrc: `https://jwj.kr/upload/member/${itm.profilePhoto}`,
          memName: ``,
          text: decodeChar(itm.diaryCnts),
          kind: "column",
          writer: "",
        })),
      ];
      return res;
    }
  }

  async function getColumnList(payload) {
    try {
      if (isEmpty(state.columnList) || state.columnList.length === 0) {
        state.columnPayload.startNum = "0";
        state.columnPaging.lastIdx = 0;
        state.columnPaging.isEnd = false;
      }

      if (state.columnPaging.isEnd === false) {
        if (!isEmpty(state.columnList) && state.columnList.length > 0) state.columnPayload.startNum = parseInt(state.columnPayload.startNum) + 10;
        const { data: res = {} } = await getColumnListAPI(state.columnPayload);
        if (res?.columnList === undefined) throw Error;

        if (res.columnList.length < 10) state.columnPaging.isEnd = true;

        state.columnList = [
          ...state.columnList,
          ...res.columnList.map((itm) => ({
            ...itm,
            date: dayjs(itm.diarySortDate).format("YYYY-MM-DD"),
            thumbUrl: !isEmpty(itm.uploadBannerFileCD) ? `https://jwj.kr/upload/${itm.uploadBannerFileSavePath}/${itm.uploadBannerFileCD}_thumb` : "",
            imgSrc: `https://jwj.kr/upload/member/${itm.profilePhoto}`,
            memName: `유기성`,
            text: decodeChar(itm.diaryCnts),
            kind: "column",
            writer: "유기성",
          })),
        ];
        return res;
      }
    } catch (err) {
      console.error(`getColumnList err : `, err);
    }
  }

  function formatDate(date) {
    const now = dayjs();
    const serverDate = dayjs(date, "YYYYMMDDHHmmss");

    // 비교 날짜를 미리 계산
    const yesterday = now.subtract(1, "day");

    if (serverDate.isSame(now, "day")) {
      // 오늘이면 "a h시 mm분" 형식으로 포맷합니다.
      return serverDate.format("A h시 mm분");
    } else if (serverDate.isSame(yesterday, "day")) {
      // 어제이면 "어제"라고 표시합니다.
      return "어제";
    } else {
      // 년도가 올해와 일치하지 않으면 "YYYY년 M월 D일" 형식으로 포맷합니다.
      if (!serverDate.isSame(now, "year")) {
        return serverDate.format("YYYY년 M월 D일");
      } else {
        // 그보다 이전이면 "M월 D일" 형식으로 포맷합니다.
        return serverDate.format("M월 D일");
      }
    }
  }

  async function getMomentList() {
    try {
      state.memoStartNum;
      const { statusText, data: res = {} } = await getMomentListAPI({ startNum: state.memoStartNum });
      if (statusText !== "OK" || res?.momentList === undefined) throw Error;

      if (state.memoStartNum == 0) {
        state.momentList = [];
        state.momentList = [
          ...res.momentList.map((itm) => ({
            ...itm,
            memName: itm.memNm,
            text: decodeChar(itm.momentCnts).replace(/<[^>]*>/g, ""),
            //date: dayjs(itm.regLDate).format("a h시 mm분"),
            date: formatDate(itm.regLDate),
            ...(!isEmpty(itm.uploadFileSaveName) && {
              imgSrc: `https://jwj.kr/upload${itm.uploadFileSavePath}${itm.uploadFileSaveName}`,
            }),
            kind: "daily",
          })),
        ];
      } else if (state.memoStartNum > 0) {
        state.momentList = [
          ...state.momentList,
          ...res.momentList.map((itm) => ({
            ...itm,
            memName: itm.memNm,
            text: decodeChar(itm.momentCnts).replace(/<[^>]*>/g, ""),
            //date: dayjs(itm.regLDate).format("a h시 mm분"),
            date: formatDate(itm.regLDate),
            ...(!isEmpty(itm.uploadFileSaveName) && {
              imgSrc: `https://jwj.kr/upload${itm.uploadFileSavePath}${itm.uploadFileSaveName}`,
            }),
            kind: "daily",
          })),
        ];
      }

      //console.log("state.momentList :: ", state.momentList);
      return state.momentList;
    } catch (err) {
      console.error(`getMomentList err : `, err);
    }
  }
  async function getColumnRcmdList() {
    try {
      const { data: res = {} } = await getColumnRcmdListAPI();
      if (res?.columnList === undefined) throw Error;
      state.openRcmdList = [
        ...res.columnList.map((itm) => ({
          ...itm,
          //diaryCnts: itm.diaryCnts.replace(/(\n|\r\n)/g, '<br/>')
          ...(!isEmpty(itm.memProfileCd) && {
            imgSrc: `https://jwj.kr/upload/member/${itm.memProfileCd}`,
          }),
          text: decodeChar(itm.diaryCnts),
        })),
      ];
      return state.openRcmdList;
    } catch (err) {
      console.error(`getColumnRcmdList err : `, err);
    }
  }
  async function getRcmdList(payload) {
    try {
      const { statusText, data: res = {} } = await getRcmdListAPI(payload);
      if (statusText !== "OK" || res?.rcmdDiaryList === undefined) throw Error;

      state.rcmdList = [
        ...state.rcmdList,
        ...res.rcmdDiaryList.map((itm) => ({
          ...itm,
          text: decodeChar(itm.diaryCnts),
          date: dayjs(itm.diarySaveLDate).format("YYYY-MM-DD"),
          likeItCount: itm.amenCount,
          ...(!isEmpty(itm.uploadFileSaveName) && {
            imgSrc: `https://jwj.kr/upload${itm.uploadFileSavePath}${itm.uploadFileSaveName}`,
          }),
          ...(!isEmpty(itm.profilePhoto) && {
            profileImgSrc: `https://jwj.kr/upload${itm.uploadFileSavePath}${itm.profilePhoto}`,
          }),
          ...(!isEmpty(itm.uploadFileCd)
            ? {
                thumbUrl: `https://jwj.kr/upload/diary/${itm.uploadFileCd}_thumb`,
              }
            : { thumbUrl: "" }),
          author: { name: itm.memName },
          kind: "rcmd",
        })),
      ];
    } catch (err) {
      console.error(`getRcmdList err : `, err);
    }
  }
  function rcmdUnReadActor(code) {
    state.rcmdList = state.rcmdList.map((itm) => ({ ...itm }));
  }
  async function getColumnDet(code) {
    try {
      state.columnDet = {};
      const { statusText, data: res = {} } = await getColumnDetAPI(code);

      if (statusText !== "OK" || res?.result === undefined) throw Error;

      const prefix = "https://jwj.kr";
      const regex = /(<img src=")([^"]*)/g;
      const imgTagRegex = /<img[^>]*>/g;
      const regexSrc = /src="([^"]*)/i;
      state.columnDet = {
        ...res.result,
        //diaryCnts: res.result.diaryCnts.replace(regex, `$1${prefix}$2`),
        //diaryCnts: res.result.diaryCnts.replace(imgTagRegex, ``),
        //...(!isEmpty(res.result.diaryCnts.match(regexSrc)) ? { headImg: `${prefix}${res.result.diaryCnts.match(regexSrc)[1]}` } : { headImg: null }),
        ...(res.result.uploadFileCd ? { headImg: `${$origin.value}/upload/diary/${res.result.uploadFileCd}` } : { headImg: null }),
        author: {
          memName: res.result.memName,
          //imgSrc: `https://jwj.kr/upload/${res.result.uploadFileSavePath}${res.result.uploadFileSaveName}`
        },
        imgSrc: `${$origin.value}/upload${res.result.uploadFileSavePath}${res.result.uploadFileSaveName}`,
        blocking: res.result.blocking,
      };
      return state.columnDet;
    } catch (err) {
      console.error(`getColumnDet err : `, err);
    }
  }
  async function getMyDailyChkListActor(diaryCd) {
    try {
      const { data: res = {} } = await getMyDailyChkListAPI(diaryCd);

      const routineList = res.result.reduce((acc, cur, idx) => {
        if (cur.langMssgNm.includes("@")) {
          let splited = cur.langMssgNm.split("@");
          splited.forEach((itm, idx) => {
            acc.push({
              ...cur,
              dailyCheckCd: idx == 0 ? `DailyCheck0000000004` : `DailyCheck0000000005`,
              dailyCheckName: itm,
              langMssgNm: itm,
              userInput: cur[`userInput${idx + 1}`],
            });
          });
        } else {
          acc.push({ ...cur, dailyCheckName: cur.langMssgNm, userInput: cur.userInput1 });
        }
        return acc;
      }, []);

      return routineList;
    } catch (err) {
      console.error(`getMyDailyChkListActor err : `, err);
    }
  }
  async function getDiaryDet({ diaryCd, memCd, boardCd, roomCd }) {
    try {
      const { statusText, data: res = {} } = await getDiaryDetAPI({
        diaryCd,
        boardCd: boardCd || `Board000000000000007`,
        ...(!isEmpty(memCd) && { memCd }),
        ...(!isEmpty(roomCd) && { roomCd }),
      });
      if (statusText !== "OK" || res?.result === undefined) throw Error;
      // prettier-ignore
      let routineList = [];
      if (authStore.userInfo.memCd) {
        routineList = await getMyDailyChkListActor(diaryCd);
      }

      const prefix = "https://jwj.kr";
      const regex = /(<img src=")([^"]*)/g;
      const imgTagRegex = /<img[^>]*>/g;
      const regexSrc = /src="([^"]*)/i;

      const imgSrc = !isEmpty(res.result.uploadFileSaveName)
        ? `https://jwj.kr/upload${res.result.uploadFileSavePath}${res.result.uploadFileSaveName}`
        : null;

      let headImg = "";

      let imgTagRegex2 = /<img[^>]*src="([^"]*)"[^>]*>/;

      let match = imgTagRegex2.exec(res.result.diaryCnts);

      if (res.result.uploadFileCd) {
        headImg = `https://jwj.kr/upload/diary/${res.result.uploadFileCd}`;
      } else {
        /* if (match) {
          headImg = match[1]; // 첫 번째 이미지 태그의 src 값을 추출
          res.result.diaryCnts = res.result.diaryCnts.replace(match[0], ""); // 첫 번째 이미지 태그를 문자열에서 제거
        }

        if (!headImg.startsWith("https://jwj.kr") || headImg.startsWith("/")) {
          headImg = "https://jwj.kr" + (headImg.startsWith("/") ? "" : "/") + headImg;
        }
        if (headImg == "https://jwj.kr/") headImg = ""; */
        headImg = null;
      }
      const imgJwjRegex = /<img\b[^>]*?\bsrc=['"]((?!https:\/\/)[^'"]+)['"][^>]*?>/gi;

      state.diaryDet = {
        ...res.result,
        ...(authStore.userInfo?.memCd && res.result.memCd == authStore.userInfo.memCd && { routineList }),
        dailyCheck: res.result?.dailyCheck ? JSON.parse(res.result.dailyCheck) : "",
        originCnts: res.result.diaryCnts,
        //diaryCnts: res.result.diaryCnts.replace(imgTagRegex, ``),
        //diaryCnts: res.result.diaryCnts.replace(/<img src="\/([^"]+)"/g, '<img src="https://jwj.kr/$1"'),
        diaryCnts: res.result.diaryCnts.replace(imgJwjRegex, (match, p1) => {
          return match.replace(p1, `https://jwj.kr/${p1}`);
        }),
        headImg: !isEmpty(headImg) ? `${headImg}` : "",
        author: {
          memName: res.result.memName,
          imgSrc,
        },
        imgSrc,
        blocking: res.blocking,
        roomToDisclose: res.result2?.roomName || null,
        ...(res.result?.tags ? JSON.parse(res.result.tags) : {}),
      };

      return state.diaryDet;
    } catch (err) {
      console.error(`getDiaryDet err : `, err);
    }
  }
  /**
   * @title [041] 댓글 저장 API
   * diaryCd, boardCd(칼럼 : Board000000000000005, 일기 : Board000000000000006, 추천 : Board000000000000007)
   * memCd, postCnts
   * postLevel, postSortNum, regLDate
   */
  async function insertCmtActor(payload) {
    try {
      const { data: res = {} } = await insertCmtAPI(payload);
      return res;
    } catch (err) {
      console.error(`insertCmtActor err : `, err);
    }
  }
  async function updateCmtActor(payload) {
    const { data: res = {} } = await updateCmtAPI(payload);
    return res;
  }
  async function getCmtList({ diaryCd, boardCd, roomCd, refresh }) {
    try {
      if (refresh == "refresh") {
        state.groupedCmt = [];
        state.cmtList = [];
      }
      const { statusText, data: res = {} } = await getCommentListAPI({
        diaryCd,
        boardCd,
        ...(!isEmpty(roomCd) && { roomCd }),
      });

      /* const {statusText, data:res={}} = await getShareRoomCmtListAPI({
          diaryCd,
          boardCd,
        }) */
      //if (statusText !== "OK" || res?.result === undefined) throw Error;

      state.cmtList = [...res.result];
      state.groupedCmt = getCmtPaging(state.cmtList, 10);
      return true;
    } catch (err) {
      console.error(`getCmtList err : `, err);
    }
  }
  async function getShareRoomCmtListActor({ roomCd, diaryCd, refresh }) {
    try {
      if (refresh == "refresh") {
        state.groupedCmt = [];
        state.cmtList = [];
      }

      const { statusText, data: res = {} } = await getShareRoomCmtListAPI({
        roomCd,
        diaryCd,
        BoardCd: "Board000000000000006",
      });

      if (statusText !== "OK" || res?.result === undefined) throw Error;
      state.cmtList = [...res.result];
      state.groupedCmt = getCmtPaging(state.cmtList, 10);
      return state.groupedCmt;
    } catch (err) {
      console.error(`getShareRoomCmtListActor err : `, err);
    }
  }
  /* async getRoomListAction(orderBy) {
      try {
        const { statusText, data: res = {} } = await getRoomListAPI({ orderBy });
        if (statusText !== 'OK' || typeof res !== 'object') throw Error;
        
        
         //name: '김영민속 나눔방',
         //componentName: 'Sharing',
         //category: '나눔방',
         //visible: true
        
        this.contentsList = [
          ...this.contentsList,
          ...res.roomList.map(itm => ({ ...itm, name: itm.roomName, componentName: 'Sharing', category: '나눔방', visible: true }))
        ];
      } catch (err) {
        console.error(`getRoomListAction err : `, err);
      }
    }, */
  async function getMyRoomListAction(payload) {
    try {
      const { statusText, data: res = {} } = await getMyRoomListAPI();
      if (statusText !== "OK" || typeof res !== "object") throw Error;

      const roomCdList = state.contentsList.filter((itm) => !!itm.roomCd).map((itm) => itm.roomCd);
      state.contentsList = [
        ...state.contentsList,
        ...res.roomList
          .filter((itm) => !roomCdList.includes(itm.roomCd))
          .map((itm) => ({
            ...itm,
            name: itm.roomName,
            componentName: "Sharing",
            category: "나눔방",
            visible: true,
          })),
      ];
      return state.contentsList;
    } catch (err) {
      console.error(`getMyRoomListAction err : `, err);
    }
  }

  async function getMyRoomDiaryListAction({ roomCd, startNum = 0 }) {
    try {
      const { statusText, data: res = {} } = await getMyRoomDiaryListAPI({
        roomCd,
        startNum,
      });
      if (statusText !== "OK" || res?.result === undefined) throw Error;
      state.myRoomDiaryList[roomCd] = [];

      /* if (this.myRoomDiaryList[roomCd]) {
          const roomCdList = this.myRoomDiaryList[roomCd].result.map(itm => itm.roomCd);
        } */
      /* const roomCdList = this.myRoomDiaryList[roomCd].result.map(itm => itm.roomCd);
         ", this.myRoomDiaryList[roomCd].result.map(itm => itm.roomCd))
         */

      //this.myRoomDiaryList[roomCd] = [];

      const keyList = Object.keys(state.myRoomDiaryList);
      //if (keyList.length === 0 || !keyList.includes(roomCd)) this.myRoomDiaryList[roomCd] = [];

      if (state.myRoomDiaryList[roomCd].length === 0) {
        state.myRoomDiaryList[roomCd].push({
          roomCd,
          result: res.result.map((itm) => {
            //
            const imgSrc = !isEmpty(itm.uploadFileSaveName) ? `https://jwj.kr/upload/${itm.uploadFileSavePath}${itm.uploadFileSaveName}` : null;
            return {
              ...itm,
              //decodeChar(itm.momentCnts).replace(/<[^>]*>/g, ''),
              //background:url(/upload/diary/20231105225832930152_thumb);
              thumbUrl: !isEmpty(itm.uploadFileCd) ? `https://jwj.kr/upload/diary/${itm.uploadFileCd}_thumb` : "",
              diaryCnts: decodeChar(itm.diaryCnts).replace(/<[^>]*>/g, ""),
              author: {
                memName: itm.memName,
                imgSrc,
              },
              imgSrc,
            };
          }),
        });
      }

      /*   if (!roomCdList.includes(res.roomDiaryVO.roomCd)) {
          this.myRoomDiaryList = {};
          
          this.myRoomDiaryList[roomCd] = [];
          this.myRoomDiaryList[roomCd].push({
            roomCd: roomCd,
            result: res.result.map(itm => ({
              ...itm,
              author: {
                memName: itm.memName,
                imgSrc: !isEmpty(itm.uploadFileSaveName) ? `https://jwj.kr/upload/${itm.uploadFileSavePath}${itm.uploadFileSaveName}` : null
              },
              imgSrc: !isEmpty(itm.uploadFileSaveName) ? `https://jwj.kr/upload/${itm.uploadFileSavePath}${itm.uploadFileSaveName}` : null
            }))
          });
        } */
    } catch (err) {
      console.error(`geyMyRoomDiaryListAction err : `, err);
    }
  }
  async function setDisclosureStatusAction({ roomCd, homeSetYn }) {
    try {
      //homeSetYn ===> string 'Y'/'N'
      const { statusText, data: res = {} } = await setDisclosureStatusAPI({
        roomCd,
        homeSetYn,
      });
      if (statusText !== "OK" || res?.result === undefined || res?.roomMemVO === undefined) throw Error;

      return res;
    } catch (err) {
      console.error(`setDisclosureStatusAction err : `, err);
    }
  }

  // 투데이 컨텐츠 순서
  async function getTodayCateListAction() {
    try {
      /* const compoMatcher = {
        Column: "Column",
        Moment: "Daily",
        Rcmd: "DiaryRecom",
        365: "WithJTraining",
      }; */
      const compoMatcher = {
        todayColumn: "Column",
        todayColumn2: "Column",
        todayGroup: "Column",
        todayMoment: "Daily",
        todayRcmd: "DiaryRecom",
        today365: "WithJTraining",
        todayRoom: "Sharing",
        todayGroupPosting: "GroupPosting",
      };

      const { statusText, data: res = {} } = await getTodayCateListAPI();
      if (statusText !== "OK" || res?.result === undefined) throw Error;

      state.contentsList = res.result.map((itm) => {
        //const isRoom = Object.keys(compoMatcher).includes(itm.catCd);
        return {
          ...itm,
          visible: itm.visible === "Y" ? "Y" : "N",
          category: itm.catCd,
          categoryName: itm.catCd,
          componentName: compoMatcher[itm.catCd],
        };
      });
      /* state.contentsList = res.result.reduce((acc, cur) => {
        acc.push({ ...cur, visible: acc.visible === "Y" ? "Y" : "N", category: cur.catCd, componentName: compoMatcher[cur.catCd] });

        return acc;
      }, []); */

      return state.contentsList;
    } catch (err) {
      console.error(`getTodayCateListAction err : `, err);
    }
  }
  async function saveTodayCateListAction(payload) {
    try {
      //투데이 컨텐츠 순서 저장
      /* 
          [{visible:'Y',category,code(Sharing일 경우에만)}]
        */
      const res = await saveTodayCateListAPI(payload);
      return res;
    } catch (err) {
      console.error(`saveTodayCateListAction err : `, err);
    }
  }
  async function insertMomentProcAction({ momentCnts, regLDate, siteLangCd }) {
    try {
      const { statusText, data: res = {} } = await insertMomentProcAPI({
        momentCnts,
        regLDate,
        siteLangCd,
      });
      if (statusText !== "OK" || res.todayCnt === undefined) throw Error;
      return true;
    } catch (err) {
      console.error(`insertMomentProcAction err : `, err);
    }
  }
  function getCmtPaging(array, perGroup) {
    const result = {};
    for (let i = 0, max = array.length; i < max; i++) {
      const groupIndex = Math.floor(i / perGroup);
      if (!result[groupIndex]) {
        result[groupIndex] = [];
      }
      result[groupIndex].push(array[i]);
    }
    return result;
  }
  function getTimeDiff(timeToCompare) {
    try {
      const timeDiffDuration = $dayjs.duration($dayjs().diff(timeToCompare));
      const yearDiff = parseInt(timeDiffDuration.format("Y"));
      const monthDiff = parseInt(timeDiffDuration.format("M"));
      const dateDiff = parseInt(timeDiffDuration.format("D"));
      const hourDiff = parseInt(timeDiffDuration.format("H"));
      const minuteDiff = parseInt(timeDiffDuration.format("m"));
      const secondDiff = parseInt(timeDiffDuration.format("s"));

      if (yearDiff > 0) {
        return `${yearDiff}년 전`;
      } else if (monthDiff > 0) {
        return `${monthDiff}달 전`;
      } else if (dateDiff > 0) {
        return `${dateDiff}일 전`;
      } else if (hourDiff > 0) {
        return `${hourDiff}시간 전`;
      } else if (minuteDiff > 0) {
        return `${minuteDiff}분 전`;
      } else if (secondDiff > 0) {
        return `${secondDiff}초 전`;
      } else {
        return "";
      }
    } catch (err) {
      console.error(`getTimeDiff err : `, err);
    }
  }

  async function getJWJTrainingListActor() {
    try {
      //console.log("authStore.userInfo :: ", authStore.userInfo);
      const payload = { key: btoa(authStore.userInfo.loginId) };
      const { statusText, data: res = [] } = await axios.post("https://365.jwj.kr/api-course-step", payload);
      if (statusText !== "OK" || res === undefined) throw Error;
      state.trainingList = res.map((itm) => itm.data);
      //console.log("예수동행훈련 목록 ::: ", state.trainingList);
      return state.trainingList;
    } catch (err) {
      console.error(`getJWJTrainingListActor err : `, err);
    }
  }

  async function tmpSaveMemoActor(payload) {
    return await tmpSaveMemoAPI(payload);
  }

  async function getTmpMemoActor() {
    return await getTmpMemoAPI();
  }

  function parseNotificationCode(input) {
    // 콜론(:)을 기준으로 문자열을 분리합니다.
    const codes = input.split(",").map((item) => item.trim());

    // 결과를 저장할 객체를 초기화합니다.
    const result = {};

    // 각 항목을 순회하며 'Y'와 'N'에 해당하는 코드를 객체에 저장합니다.
    codes.forEach((code) => {
      const [key, value] = code.split(":").map((item) => item.trim());
      result[key] = value;
    });

    return result;
  }

  async function getTodayAlarmActor() {
    try {
      state.myAlarm = null;
      const { data: res = {} } = await getTodayAlarmAPI();
      if (res.result.length === 0) return res;

      state.myAlarm = res.result[0];
      state.myAlarm.confirmCode = parseNotificationCode(state.myAlarm.fdbckNotiCd);
      return res;
    } catch (err) {
      console.error(`getTodayAlarmActor err : `, err);
      state.myAlarm = null;
    }
  }

  function parseJsonString(jsonString, from) {
    // 역슬래시 제거
    //const cleanedJsonString = jsonString.replace(/\\/g, "");
    // JSON 문자열을 자바스크립트 객체로 파싱
    //return JSON.parse(cleanedJsonString);
    return JSON.parse(jsonString);
  }

  async function getTodayListActor() {
    try {
      state.roomPaging.startNum = 0;
      state.rcmdPaging.startNum = 0;
      const { data: res = {} } = await getTodayListAPI();
      /* const pagingData = await getTodayDataPagingActor({ startNum: "0", gubun: "room" });
      console.log("pagingData :: ", pagingData); */

      // 함수 정의를 map 바깥으로 이동
      const parseData = (data) => (data ? parseJsonString(data) : null);
      const getProfileImgUrl = (itm) => (itm.profilePhoto ? `https://jwj.kr/upload/member/${itm.profilePhoto}` : "");
      const getCardImageUrl = (itm) => (itm.uploadFileSaveName ? `https://jwj.kr/upload/${itm.uploadFileSavePath}/${itm.uploadFileSaveName}` : "");
      const imgTagRegex2 = /<img[^>]*src="([^"]*)"[^>]*>/;

      state.newList = res.result.map((cur) => {
        const catData = parseData(cur.catData)?.map((itm) => {
          const hasImgTag = imgTagRegex2.test(itm.someHtmlField || ""); // img 태그 검사, itm.someHtmlField는 실제 img 태그가 있을 수 있는 필드로 대체 필요
          return {
            ...itm,
            sectionName: cur.catCd,
            profileImg: getProfileImgUrl(itm),
            cardImg: getCardImageUrl(itm),
            isImg: hasImgTag, // img 태그 유무에 따라 isImg 설정
          };
        });

        return {
          ...cur,
          catData,
          catInfo: parseData(cur.catInfo),
        };
      });

      console.log("state.newList :: ", state.newList);

      return res;
    } catch (err) {
      console.error("getTodayListActor error: ", err);
      throw err;
    }
  }

  /**
   * @param {string} startNum
   * @param {string} grpCd
   * @returns
   */
  async function getPostingListActor(payload) {
    try {
      if (isEmpty(state.groupPostingList) || state.groupPostingList.length === 0) {
        state.groupPostingPaging.lastIdx = 0;
        state.groupPostingPaging.isEnd = false;
      }

      if (state.groupPostingPaging.isEnd === false) {
        if (state.groupPostingList?.length > 0) {
          state.grpPayload.startNum = parseInt(state.grpPayload.startNum) + 10;
        }
        const { data: res = {} } = await getPostingListAPI({ startNum: state.grpPayload.startNum, grpCd: payload.grpCd });
        if (res?.result === undefined) throw Error;
        if (res.result.length < 10) state.groupPostingPaging.isEnd = true;
        state.groupPostingList = [...state.groupPostingList, ...res.result];
        //state.groupPostingList = res.result;
        return res;
      }
    } catch (err) {
      console.error(`getPostingListActor err : `, err);
    }
  }

  async function getColumnAuthInfoActor() {
    try {
      const { data: res = {} } = await getColumnAuthInfoAPI();
      if (!res?.result) throw Error;

      state.columnAuth = res.result;
      return state.columnAuth;
    } catch (err) {
      console.error(`getColumnAuthInfoActor err : `, err);
    }
  }

  /**
   * @param {string} startNum : 0, 10, 20, ...
   * @param {string} gubun : 나눔방->room, 추천일기->rcmd
   */
  async function getTodayDataPagingActor(payload) {
    try {
      const { data: res = {} } = await getTodayDataPagingAPI(payload);
      const idx = state.newList.findIndex((itm) => itm.catCd === (payload.gubun === "room" ? "todayRoom" : "todayRcmd"));
      state.newList[idx].catData = [...state.newList[idx].catData, ...res.result];

      return res;
    } catch (err) {
      console.error(`getTodayDataPagingActor err : `, err);
    }
  }

  return {
    state,
    setOrderModal,
    setDrag,
    getBannerList,
    getColumnList,
    getMomentList,
    getColumnRcmdList,
    getRcmdList,
    rcmdUnReadActor,
    getColumnDet,
    getDiaryDet,
    insertCmtActor,
    updateCmtActor,
    getCmtList,
    getShareRoomCmtListActor,
    getMyRoomListAction,
    getMyRoomDiaryListAction,
    setDisclosureStatusAction,
    getTodayCateListAction,
    saveTodayCateListAction,
    insertMomentProcAction,
    getCmtPaging,
    getTimeDiff,
    getJWJTrainingListActor,
    tmpSaveMemoActor,
    getTmpMemoActor,
    getTodayAlarmActor,
    getTodayListActor,
    getWithTodayStoryList,
    getPostingListActor,
    getColumnAuthInfoActor,
    getTodayDataPagingActor,
  };
});
